.scrap-return-button-container {
    // width: 100px;
    height: 35px;
}

.btn-common {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.image-upload-container {
    border: 1px solid #ced4da;
    height: 100%;
    width: 84%;
    @media (max-width:770px) {
        width: 100%;
    }
}
.container {
    height: 210px;
}
.div-container {
    display: flex;
    justify-content: right;
}
.imageHandle {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.image-preview-container {
    padding: 10px;
    width: 200px;
    height: 200px;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.custom-date-picker {
    display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    appearance: none;
    // background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
