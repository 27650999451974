.back-button-container {
    display: flex;
    align-items: center;
    flex-shrink: 0;  
    white-space: nowrap;  
  }
  
  @media (max-width: 768px) {
    .back-button-container {
      padding-right: 1rem !important; 
      margin-bottom: 0 !important;  
    }
  }